exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-anime-awards-and-testimonials-js": () => import("./../../../src/pages/anime-awards-and-testimonials.js" /* webpackChunkName: "component---src-pages-anime-awards-and-testimonials-js" */),
  "component---src-pages-anime-home-js": () => import("./../../../src/pages/anime-home.js" /* webpackChunkName: "component---src-pages-anime-home-js" */),
  "component---src-pages-anime-work-js": () => import("./../../../src/pages/anime-work.js" /* webpackChunkName: "component---src-pages-anime-work-js" */),
  "component---src-pages-awards-and-testimonials-js": () => import("./../../../src/pages/awards-and-testimonials.js" /* webpackChunkName: "component---src-pages-awards-and-testimonials-js" */),
  "component---src-pages-casestudies-e-commerce-balashi-flowers-ecommerce-website-js": () => import("./../../../src/pages/casestudies/e-commerce/balashi-flowers-ecommerce-website.js" /* webpackChunkName: "component---src-pages-casestudies-e-commerce-balashi-flowers-ecommerce-website-js" */),
  "component---src-pages-casestudies-e-commerce-change-skincare-ecommerce-website-js": () => import("./../../../src/pages/casestudies/e-commerce/change-skincare-ecommerce-website.js" /* webpackChunkName: "component---src-pages-casestudies-e-commerce-change-skincare-ecommerce-website-js" */),
  "component---src-pages-casestudies-e-commerce-flower-dose-ecommerce-website-js": () => import("./../../../src/pages/casestudies/e-commerce/flower-dose-ecommerce-website.js" /* webpackChunkName: "component---src-pages-casestudies-e-commerce-flower-dose-ecommerce-website-js" */),
  "component---src-pages-casestudies-e-commerce-fresh-fresh-flowers-ecommerce-website-js": () => import("./../../../src/pages/casestudies/e-commerce/fresh-fresh-flowers-ecommerce-website.js" /* webpackChunkName: "component---src-pages-casestudies-e-commerce-fresh-fresh-flowers-ecommerce-website-js" */),
  "component---src-pages-casestudies-e-commerce-simon-west-ecommerce-website-js": () => import("./../../../src/pages/casestudies/e-commerce/simon-west-ecommerce-website.js" /* webpackChunkName: "component---src-pages-casestudies-e-commerce-simon-west-ecommerce-website-js" */),
  "component---src-pages-casestudies-mobile-apps-afl-players-moji-mobile-application-js": () => import("./../../../src/pages/casestudies/mobile-apps/afl-players-moji-mobile-application.js" /* webpackChunkName: "component---src-pages-casestudies-mobile-apps-afl-players-moji-mobile-application-js" */),
  "component---src-pages-casestudies-mobile-apps-c-19-mobile-application-js": () => import("./../../../src/pages/casestudies/mobile-apps/c19-mobile-application.js" /* webpackChunkName: "component---src-pages-casestudies-mobile-apps-c-19-mobile-application-js" */),
  "component---src-pages-casestudies-mobile-apps-consult-mobile-application-js": () => import("./../../../src/pages/casestudies/mobile-apps/consult-mobile-application.js" /* webpackChunkName: "component---src-pages-casestudies-mobile-apps-consult-mobile-application-js" */),
  "component---src-pages-casestudies-mobile-apps-dijjoo-mobile-application-js": () => import("./../../../src/pages/casestudies/mobile-apps/dijjoo-mobile-application.js" /* webpackChunkName: "component---src-pages-casestudies-mobile-apps-dijjoo-mobile-application-js" */),
  "component---src-pages-casestudies-mobile-apps-giggers-mobile-application-js": () => import("./../../../src/pages/casestudies/mobile-apps/giggers-mobile-application.js" /* webpackChunkName: "component---src-pages-casestudies-mobile-apps-giggers-mobile-application-js" */),
  "component---src-pages-casestudies-mobile-apps-social-sounds-mobile-application-js": () => import("./../../../src/pages/casestudies/mobile-apps/social-sounds-mobile-application.js" /* webpackChunkName: "component---src-pages-casestudies-mobile-apps-social-sounds-mobile-application-js" */),
  "component---src-pages-casestudies-mobile-apps-warnie-moji-mobile-application-js": () => import("./../../../src/pages/casestudies/mobile-apps/warnie-moji-mobile-application.js" /* webpackChunkName: "component---src-pages-casestudies-mobile-apps-warnie-moji-mobile-application-js" */),
  "component---src-pages-casestudies-strapi-project-category-slug-js": () => import("./../../../src/pages/casestudies/{strapiProjectCategory.slug}.js" /* webpackChunkName: "component---src-pages-casestudies-strapi-project-category-slug-js" */),
  "component---src-pages-casestudies-web-apps-albya-web-application-js": () => import("./../../../src/pages/casestudies/web-apps/albya-web-application.js" /* webpackChunkName: "component---src-pages-casestudies-web-apps-albya-web-application-js" */),
  "component---src-pages-casestudies-web-apps-essestone-web-application-js": () => import("./../../../src/pages/casestudies/web-apps/essestone-web-application.js" /* webpackChunkName: "component---src-pages-casestudies-web-apps-essestone-web-application-js" */),
  "component---src-pages-casestudies-web-apps-jabri-web-application-js": () => import("./../../../src/pages/casestudies/web-apps/jabri-web-application.js" /* webpackChunkName: "component---src-pages-casestudies-web-apps-jabri-web-application-js" */),
  "component---src-pages-casestudies-web-apps-louis-vuitton-web-application-js": () => import("./../../../src/pages/casestudies/web-apps/louis-vuitton-web-application.js" /* webpackChunkName: "component---src-pages-casestudies-web-apps-louis-vuitton-web-application-js" */),
  "component---src-pages-casestudies-web-apps-rww-web-application-js": () => import("./../../../src/pages/casestudies/web-apps/rww-web-application.js" /* webpackChunkName: "component---src-pages-casestudies-web-apps-rww-web-application-js" */),
  "component---src-pages-casestudies-web-apps-viktrs-web-application-js": () => import("./../../../src/pages/casestudies/web-apps/viktrs-web-application.js" /* webpackChunkName: "component---src-pages-casestudies-web-apps-viktrs-web-application-js" */),
  "component---src-pages-casestudies-web-apps-x-gap-merge-web-application-js": () => import("./../../../src/pages/casestudies/web-apps/x-gap-merge-web-application.js" /* webpackChunkName: "component---src-pages-casestudies-web-apps-x-gap-merge-web-application-js" */),
  "component---src-pages-casestudies-websites-alain-class-websites-js": () => import("./../../../src/pages/casestudies/websites/alain-class-websites.js" /* webpackChunkName: "component---src-pages-casestudies-websites-alain-class-websites-js" */),
  "component---src-pages-casestudies-websites-essestone-websites-js": () => import("./../../../src/pages/casestudies/websites/essestone-websites.js" /* webpackChunkName: "component---src-pages-casestudies-websites-essestone-websites-js" */),
  "component---src-pages-casestudies-websites-goss-websites-js": () => import("./../../../src/pages/casestudies/websites/goss-websites.js" /* webpackChunkName: "component---src-pages-casestudies-websites-goss-websites-js" */),
  "component---src-pages-casestudies-websites-industry-insider-websites-js": () => import("./../../../src/pages/casestudies/websites/industry-insider-websites.js" /* webpackChunkName: "component---src-pages-casestudies-websites-industry-insider-websites-js" */),
  "component---src-pages-casestudies-websites-kawa-haji-websites-js": () => import("./../../../src/pages/casestudies/websites/kawa-haji-websites.js" /* webpackChunkName: "component---src-pages-casestudies-websites-kawa-haji-websites-js" */),
  "component---src-pages-casestudies-websites-komodo-websites-js": () => import("./../../../src/pages/casestudies/websites/komodo-websites.js" /* webpackChunkName: "component---src-pages-casestudies-websites-komodo-websites-js" */),
  "component---src-pages-casestudies-websites-marble-care-websites-js": () => import("./../../../src/pages/casestudies/websites/marble-care-websites.js" /* webpackChunkName: "component---src-pages-casestudies-websites-marble-care-websites-js" */),
  "component---src-pages-casestudies-websites-natural-aesthetique-websites-js": () => import("./../../../src/pages/casestudies/websites/natural-aesthetique-websites.js" /* webpackChunkName: "component---src-pages-casestudies-websites-natural-aesthetique-websites-js" */),
  "component---src-pages-casestudies-websites-ozcan-websites-js": () => import("./../../../src/pages/casestudies/websites/ozcan-websites.js" /* webpackChunkName: "component---src-pages-casestudies-websites-ozcan-websites-js" */),
  "component---src-pages-casestudies-websites-runway-websites-js": () => import("./../../../src/pages/casestudies/websites/runway-websites.js" /* webpackChunkName: "component---src-pages-casestudies-websites-runway-websites-js" */),
  "component---src-pages-casestudies-websites-sixone-sunray-websites-js": () => import("./../../../src/pages/casestudies/websites/sixone-sunray-websites.js" /* webpackChunkName: "component---src-pages-casestudies-websites-sixone-sunray-websites-js" */),
  "component---src-pages-casestudy-essestone-js": () => import("./../../../src/pages/casestudy-essestone.js" /* webpackChunkName: "component---src-pages-casestudy-essestone-js" */),
  "component---src-pages-casestudy-komodo-image-tag-js": () => import("./../../../src/pages/casestudy-komodo-image-tag.js" /* webpackChunkName: "component---src-pages-casestudy-komodo-image-tag-js" */),
  "component---src-pages-casestudy-komodo-js": () => import("./../../../src/pages/casestudy-komodo.js" /* webpackChunkName: "component---src-pages-casestudy-komodo-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-fff-casestudy-js": () => import("./../../../src/pages/fff-casestudy.js" /* webpackChunkName: "component---src-pages-fff-casestudy-js" */),
  "component---src-pages-four-o-four-js": () => import("./../../../src/pages/four-o-four.js" /* webpackChunkName: "component---src-pages-four-o-four-js" */),
  "component---src-pages-home-optimize-js": () => import("./../../../src/pages/home-optimize.js" /* webpackChunkName: "component---src-pages-home-optimize-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-new-home-2-js": () => import("./../../../src/pages/new-home-2.js" /* webpackChunkName: "component---src-pages-new-home-2-js" */),
  "component---src-pages-new-home-js": () => import("./../../../src/pages/new-home.js" /* webpackChunkName: "component---src-pages-new-home-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-services-artificial-intelligence-js": () => import("./../../../src/pages/services/artificial-intelligence.js" /* webpackChunkName: "component---src-pages-services-artificial-intelligence-js" */),
  "component---src-pages-services-mobile-app-js": () => import("./../../../src/pages/services/mobile-app.js" /* webpackChunkName: "component---src-pages-services-mobile-app-js" */),
  "component---src-pages-services-mobileapps-old-js": () => import("./../../../src/pages/services/mobileapps-old.js" /* webpackChunkName: "component---src-pages-services-mobileapps-old-js" */),
  "component---src-pages-services-offshore-software-developers-js": () => import("./../../../src/pages/services/offshore-software-developers.js" /* webpackChunkName: "component---src-pages-services-offshore-software-developers-js" */),
  "component---src-pages-services-web-application-development-js": () => import("./../../../src/pages/services/web-application-development.js" /* webpackChunkName: "component---src-pages-services-web-application-development-js" */),
  "component---src-pages-services-website-design-js": () => import("./../../../src/pages/services/website-design.js" /* webpackChunkName: "component---src-pages-services-website-design-js" */),
  "component---src-pages-services-website-design-old-js": () => import("./../../../src/pages/services/website-design-old.js" /* webpackChunkName: "component---src-pages-services-website-design-old-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */)
}

